/*! _pages-landing-v4.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Landing kit 5 styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Hero
1. Navbar
2. Titles
3. Sections
4. Layout elements
5. App Showcase
6. Team
7. Testimonials
8. Contact Form
9. Quick Signup
10. Login / Signup
11. Main Post
12. Side Posts
13. Media Queries
14. Blog
=============================================================================
***/

/* ==========================================================================
0. Hero
========================================================================== */

//Hero header with wave shape
.hero.is-feature-wave:before {
  background: transparent url(../images/bg/shapes/feature-wave.png) no-repeat
    scroll 0 bottom / 100% auto;
  content: "";
  height: 321px;
  width: 100%;
  z-index: 1 !important;
  position: absolute;
  bottom: -1px;
  left: 0;
}

//Pricing Hero
.hero.is-feature-wave.is-pricing:before {
  background: transparent url(../images/bg/shapes/feature-wave.png) no-repeat
    scroll 0 bottom / 100% auto;
  content: "";
  height: 321px;
  width: 100%;
  z-index: 1 !important;
  position: absolute;
  bottom: -1px;
  left: 0;
  .hero-body {
    position: relative;
    z-index: 2;
  }
}

//Hero image
.hero-image {
  max-width: 800px;
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

//hero Mockup
.hero-app {
  position: relative;
  text-align: center;
  z-index: 2;
  img {
    border-radius: 4px;
    border: 1px solid $fade-grey;
    box-shadow: 0 3px 10px 4px rgba(0, 0, 0, 0.04);
  }
}

/* ==========================================================================
1. Navbar
========================================================================== */

.navbar {
  .navbar-item.is-button .button {
    &:hover {
      background: $secondary !important;
      color: $white !important;
    }
  }
}

.is-hero-title {
  z-index: 2;
}

/* ==========================================================================
2. Titles
========================================================================== */

.title {
  font-family: "Nexa Bold", sans-serif;
  &.is-bigger {
    font-size: 5rem;
  }
  &.is-medium {
    font-size: 4rem;
  }
}

.special-divider {
  margin: 30px auto;
  span {
    display: block;
    height: 2px;
    width: 60px;
    background: $secondary;
    border-radius: 50px;
    margin: 15px auto;
    transform: rotate(-45deg);
  }
  &.is-inverted {
    span {
      background: $white;
    }
  }
}

/* ==========================================================================
3. Sections
========================================================================== */

.section {
  position: relative;
  &.gradient-circle {
    background: url(../images/bg/shapes/gradient-circle.png) repeat top right,
      #fff;
  }
  //top line
  &:before {
    content: "";
    width: 2px;
    height: 40px;
    background: $secondary;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(180deg);
  }
  //bottom line
  &:after {
    content: "";
    width: 2px;
    height: 40px;
    background: $secondary;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(180deg);
  }
  &.no-line-bottom {
    &:after {
      display: none !important;
    }
  }
  //Wave shape
  &.wavy-bottom {
    //Hero header with wave shape
    &:after {
      background: transparent url(../images/bg/shapes/blue-waves.png) no-repeat
        scroll 0 bottom / 100% auto;
      content: "";
      height: 270px;
      width: 100%;
      z-index: 1 !important;
      position: absolute;
      bottom: -1px;
      left: 0;
      display: block !important;
      transform: rotate(0);
    }
  }
}

//divider
.title-divider {
  background: $secondary !important;
  height: 3px;
  border-radius: 50px;
}

//Element Background image
.has-background-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &.is-contain {
    background-size: contain;
    background-repeat: repeat;
  }
}

//Feature boxes
.is-feature {
  border: 1px solid lighten($fade-grey, 3%);
  border-radius: 6px;
  box-shadow: inset rgba(143, 160, 241, 0.09) 0 0 0 1px,
    rgba(213, 220, 247, 0.22) 0 10px 20px;
  height: 100%;
  .icon-container {
    height: 70px;
    width: 70px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    &.is-first {
      background: #e0ecff;
    }
    &.is-second {
      background: #f6dffb;
    }
    &.is-third {
      background: #dffee4;
    }
    img {
      height: 40px;
      width: 40px;
      display: block;
    }
  }
  .content-container {
    h3 {
      padding: 10px 0;
      font-family: "Nexa Bold", sans-serif;
    }
    p {
      font-size: 0.95rem;
      font-family: "Roboto", sans-serif;
      color: $muted-grey;
    }
  }
}

//
.featured-drawing {
  max-width: 450px;
  display: block;
  margin: 0 auto;
}

//Icon feature
.icon-feature {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &:hover {
    .icon-block .icon-inner {
      border-bottom-color: $secondary;
    }
    .feature-text {
      h3 {
        color: $secondary;
      }
    }
  }
  .icon-block {
    border-radius: 6px;
    height: 100px;
    width: 100px;
    .icon-inner {
      box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.18) !important;
      height: 100px;
      width: 100px;
      display: flex;
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      border: 3px solid transparent;
      transition: all 0.3s;
      img {
        height: 46px;
        width: 46px;
      }
    }
  }
  .feature-text {
    margin: 0 20px;
    h3 {
      color: $blue-grey;
      font-family: "Nexa Bold", sans-serif;
      font-weight: 500;
      font-size: 1.2rem;
      margin-bottom: 6px;
      transition: all 0.3s;
    }
    p {
      color: $muted-grey;
    }
  }
}

/* ==========================================================================
4. Layout elements
========================================================================== */

//Icon box
.square-icon-box {
  .box-title,
  .box-text {
    color: $blue-grey !important;
  }
}

//UI mockup
.featured-ui {
  max-height: 500px;
  display: block;
  margin: 0 auto;
}

//feature image
.featured-svg {
  max-width: 450px;
}

//Links
.is-link {
  color: $secondary !important;
}

//CTA spacing
.cta-wrapper {
  padding: 60px 0;
}

/* ==========================================================================
5. App Showcase
========================================================================== */

//Feature text
.showcase-text-wrapper {
  padding: 0 40px;
}

//Wrapper
.showcase-wrapper {
  position: relative;
}

//Mockup Wrap
.showcase-wrap {
  width: 100%;
  height: 100%;
  .app-showcase {
    border-radius: 4px;
    border: 1px solid $fade-grey;
    box-shadow: 0 3px 10px 4px rgba(0, 0, 0, 0.04);
    transition: all 0.6s;
    transform: translateX(-100%);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  //Active state
  &.is-active {
    .app-showcase {
      transform: translateX(0);
      opacity: 1;
      position: relative;
      z-index: 0;
    }
  }
}

//Slider controls
.slide-control {
  max-width: 350px;
  margin: 60px auto;
  display: flex;
  justify-content: center;
  .slide-dot {
    width: 60px;
    height: 60px;
    margin: 0 10px;
    transition: all 0.3s;
    cursor: pointer;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid $fade-grey;
    }
    &.is-active {
      transform: scale(1.3);
      img {
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

/* ==========================================================================
6. Team
========================================================================== */

//Team member
.hero-box {
  padding: 20px;
  border-radius: 6px;
  width: 100%;
  background: $white;
  border: 1px solid $fade-grey;
  img {
    height: 50px;
    display: block;
    margin: 10px auto;
  }
  .name {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
  }
  .position {
    font-size: 0.9rem;
    font-weight: 600;
    color: $secondary;
  }
  .social {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 2px;
      color: $primary;
      transition: all 0.3s;
      i {
        font-size: 18px;
        transition: all 0.3s;
      }
      &:hover {
        background: $primary;
        i {
          color: $white;
        }
      }
    }
  }
  p {
    padding: 10px 0;
    color: $muted-grey;
  }
}

/* ==========================================================================
7. Testimonials
========================================================================== */

//Wrapper
.testimonials-wrapper {
  //Testimonial item
  .testimonial {
    .fa-quote-left {
      font-size: 1.3rem;
      color: $white;
      padding: 15px;
      border-radius: 50%;
      background: $primary;
      position: relative;
      left: 19px;
      box-shadow: 0 10px 22px rgba(0, 0, 0, 0.2);
    }

    .testimonial-title {
      margin: 0 0 20px 22px;
      font-weight: normal;
      font-size: 22px;
      color: rgba(255, 255, 255, 0.7);
    }

    .testimonial-quote {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border: none;
      footer::before {
        content: "";
      }
    }

    .testimonial-text {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 200px;
      max-width: 500px;
      padding: 30px 30px 30px 30px;
      font-size: 16px;
      line-height: 1.55;
      color: #888;
      background: $white;
      border-radius: 0 30px;
      box-shadow: 0 10px 22px rgba(0, 0, 0, 0.2);
    }

    .testimonial-meta {
      display: flex;
      align-items: center;
    }

    .testimonial-img {
      position: relative;
      left: -40px;
      min-width: 80px;
      width: 80px;
      height: 80px;
      //margin-right: 20px;
      font-size: 16px;
      border-radius: 50%;
      background: $white;
      border: solid 2px $white;
      box-shadow: 0 10px 22px rgba(0, 0, 0, 0.2);
    }

    .testimonial-author {
      text-align: center;
      font-family: "Nexa Bold", sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 1.1;
      //   color: $white;
    }

    .testimonial-position {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  //Screens other than mobile
  @media (min-width: 768px) {
    .testimonial-img {
      min-width: 116px;
      width: 116px;
      height: 116px;
      border-width: 3px;
    }
    .testimonial-text {
      flex-direction: column;
      max-width: 70%;
      padding: 50px 22px;
      padding-right: 90px;
      border-radius: 0 50px;
    }
  }
  //Mobile and small Tablets
  @media (max-width: 768px) {
    .testimonial-text {
      margin-bottom: 20px;
      padding: 20px !important;
    }
    .testimonial-img {
      min-width: 60px !important;
      width: 60px !important;
      height: 60px !important;
    }
  }
  //Mobile
  @media (max-width: 767px) {
    .testimonial-quote {
      flex-wrap: wrap;
    }
    .fa-quote-left {
      top: 20px;
      left: 0 !important;
    }
    .testimonial-img {
      top: -40px;
      left: 0 !important;
    }
  }
  //Tablet portrait
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .fa-quote-left {
      top: -10px !important;
      left: 12px !important;
    }
    .testimonial-img {
      top: -10px !important;
      left: -26px !important;
    }
  }
  .slick-slide {
    height: 400px;
    min-height: 400px;
  }
}

.slick-dots {
  .slick-active {
    button::before {
      color: $white !important;
    }
  }
}

/* ==========================================================================
8. Contact Form
========================================================================== */

//Form
.contact-form {
  // width: 100%;
  // padding: 40px 20px;
  label {
    font-weight: 600;
    color: $blue-grey;
  }
  .submit-wrapper {
    //padding: 5px 0;
    width: 100%;
    text-align: right;
    
    button {
      float: right;
      display: flex;
      align-items: center;
    }
    a.oc-loading:after, button.oc-loading:after, span.oc-loading:after {
      position: absolute !important;
      right: 1rem !important;
    }
  }
}

//Contact Tabs
.contact-toggler {
  // padding: 40px 0;
  .tabbed-links {
    li {
      display: inline-block;
      padding: 10px;
      color: $placeholder;
      border-top: 2px solid transparent;
      cursor: pointer;
      &.is-active {
        color: $blue-grey;
        font-weight: 500;
        border-top: 2px solid $secondary;
      }
    }
  }
  //Wrapper
  .contact-blocks {
    //Tab content
    .contact-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0;
      .contact-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $primary;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        i {
          font-size: 1.3rem;
          color: $white;
        }
      }
      .contact-info {
        flex: 1;
        padding: 0 10px;

        a {
          word-break: break-all;
        }
        span {
          display: block;
          &:first-child {
            font-weight: 500;
            color: $blue-grey;
          }
          &:nth-child(2) {
            font-weight: 500;
            color: $blue;
          }
        }
      }
    }
  }
}

/* ==========================================================================
9. Quick Signup
========================================================================== */

.quick-signup {
  width: 100%;
  background: $white;
  border-radius: 6px;
  padding: 40px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
}

.trial-features {
  height: 100%;
  padding: 40px;
  background: $white;
  border-radius: 6px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  img {
    margin-top: 20px;
  }
}

/* ==========================================================================
10. Login / Sign up
========================================================================== */

//Login register
.login-register-wrapper {
  .backRight {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    background: $secondary;
  }

  .backLeft {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background: $primary;
  }

  #back {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -999;
  }

  .canvas-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  #slideBox {
    width: 50%;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
    margin-left: 50%;
    position: absolute;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .topLayer {
    width: 200%;
    height: 100%;
    position: relative;
    left: 0;
    left: -100%;
  }

  label {
    font-size: 0.8em;
    text-transform: uppercase;
  }

  .left {
    width: 50%;
    height: 100%;
    overflow: hidden;
    background: $sidebar;
    left: 0;
    position: absolute;
    label {
      color: $smoke-white;
    }
    input {
      background: transparent;
      color: $smoke-white;
      border: 1px solid lighten($sidebar, 10%);
      transition: border 0.3s;
      &:focus {
        border-color: lighten($sidebar, 20%);
      }
    }
  }

  .right {
    width: 50%;
    height: 100%;
    overflow: hidden;
    background: #f9f9f9;
    right: 0;
    position: absolute;
    label {
      color: #333;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    width: 55%;
    margin: 0 auto;
    position: relative;
    .brand {
      position: absolute;
      top: 65px;
      left: 0;
      a:hover {
        box-shadow: none !important;
      }
      img {
        height: 60px;
      }
    }
  }

  .content h2 {
    font-weight: 300;
    font-size: 2.2em;
    margin: 0.2em 0 0.1em;
  }

  .left .content h2 {
    color: $smoke-white;
    font-family: "Nexa Light", sans-serif;
  }

  .right .content h2 {
    color: $blue-grey;
    font-family: "Nexa Light", sans-serif;
  }

  .form-element {
    margin: 1.6em 0;
    &.form-submit {
      margin: 1.6em 0 0;
    }
  }

  .form-stack {
    display: flex;
    flex-direction: column;
  }

  button,
  .is-trigger {
    padding: 0.9em 1.4em;
    margin: 0 10px 0 0;
    width: auto;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1em;
    color: #fff;
    line-height: 1em;
    letter-spacing: 0.6px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
    border: 0;
    outline: 0;
    transition: all 0.25s;
    cursor: pointer;
    &.signup {
      background: $secondary;
    }
    &.login {
      background: $primary;
    }
    &.off {
      background: none;
      box-shadow: none;
      margin: 0;

      &.signup {
        color: $secondary;
      }
      &.login {
        color: $primary;
      }
    }
  }

  button:focus,
  button:active,
  button:hover,
  a:focus,
  a:active,
  a:hover {
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
    &.signup {
      background: darken($secondary, 5%);
    }
    &.login {
      background: darken($primary, 5%);
    }
    &.off {
      box-shadow: none;
      &.signup {
        color: $secondary;
        background: darken($sidebar, 5%);
      }
      &.login {
        color: darken($primary, 5%);
        background: #e3e3e3;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    #slideBox {
      width: 80%;
      margin-left: 20%;
    }
    .signup-info,
    .login-info {
      display: none;
    }
    .content {
      width: 80%;
    }
  }
}

/* ==========================================================================
11. Main post
========================================================================== */

//Features post
.main-post {
  //image
  .post-image {
    position: relative;
    border-radius: 6px;
    img {
      display: block;
      border-radius: 6px;
      box-shadow: $secondary-box-shadow;
    }
    //overlay
    .image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(15, 124, 255, 0.5);
      border-radius: 6px;
      transition: all 0.3s;
    }
    &:hover {
      .image-overlay {
        background: rgba(15, 124, 255, 0.1);
      }
    }
  }
  //content
  .post-content {
    padding: 20px;
    h3 {
      font-family: "Nexa Bold", sans-serif;
      font-size: 1.4rem;
      font-weight: 500;
      color: darken($secondary, 20%);
      margin: 10px 0 0 0;
      a {
        color: darken($secondary, 20%);
        transition: all 0.3s;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    //timestamp
    .time {
      display: block;
      font-size: 1.1rem;
      font-weight: 500;
      color: $muted-grey;
      margin-bottom: 16px;
    }
    p {
      font-size: 1.1rem;
      color: $muted-grey;
    }
  }

  //Read more button
  .read-more {
    display: flex;
    align-items: center;
    padding: 16px 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: $secondary;
    &.low-padding {
      padding: 8px 0;
      font-size: 1rem;
      i {
        font-size: 1.2rem;
      }
    }
    i {
      position: relative;
      top: 2px;
      font-size: 1.4rem;
      font-weight: 600;
      margin: 0 6px;
    }
  }
}

/* ==========================================================================
13. Side posts
========================================================================== */

.side-post {
  display: flex;
  align-items: start;
  margin-bottom: 20px;
  //image
  .post-image {
    position: relative;
    img {
      display: block;
      width: 100px;
      min-width: 100px;
      height: 100px;
      min-height: 100px;
      object-fit: cover;
      border-radius: 6px;
      box-shadow: $secondary-box-shadow;
    }
    //overlay
    .image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(15, 124, 255, 0.5);
      border-radius: 6px;
      transition: all 0.3s;
    }
    &:hover {
      .image-overlay {
        background: rgba(15, 124, 255, 0.1);
      }
    }
  }
  //content
  .post-content {
    flex-grow: 2;
    padding: 0 20px;
    h3 {
      font-family: "Nexa Bold", sans-serif;
      font-size: 1.2rem;
      font-weight: 500;
      color: darken($secondary, 20%);
      margin-bottom: 6px;
      a {
        color: darken($secondary, 20%);
        transition: all 0.3s;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    p {
      font-size: 1rem;
      color: $muted-grey;
    }
  }
}

/* ==========================================================================
13. Media Queries
========================================================================== */

//Ipad Pro
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

//Ipad Pro Portrait
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .is-hero-title {
    .is-bigger {
      font-size: 3.6rem;
    }
  }
}

//Ipad Pro Landscape
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
}

//Ipad Landscape
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .is-hero-title {
    .is-bigger {
      font-size: 4rem;
    }
  }
}

//Ipad Portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .hero.is-feature-wave.is-pricing:before {
    bottom: unset !important;
    top: -75px !important;
  }
  .hero-body {
    position: relative !important;
    z-index: 2 !important;
  }
  .is-hero-title {
    text-align: center;
  }
  .square-icon-box {
    .box-text {
      padding: 0 15%;
    }
  }
  .showcase-text-wrapper {
    text-align: center;
  }
  .slide-control {
    margin: 30px auto;
  }
  .switch-pricing-wrapper {
    margin-top: -30px !important;
  }
}

//Mobile
@media (max-width: 767px) {
  .is-hero-title {
    text-align: center;
    .is-bigger {
      font-size: 3rem;
    }
  }
  .slide-control {
    margin: 0 auto;
  }
  .showcase-text-wrapper {
    padding: 0;
    text-align: center;
  }
  .testimonials-wrapper {
    .slick-slide {
      height: auto;
      min-height: 340px;
    }
  }
  .submit-wrapper {
    text-align: center !important;
  }
  .switch-pricing-wrapper {
    margin-top: -55px !important;
  }
}


/****************************
 * BLOG
 ****************************/

.news {
  .post-image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }  
}

.main-post, .side-post {
  p {
    padding-bottom: .7rem;
  }
  
  h1, h2 {
    padding-top: .3rem;
    padding-bottom: .7rem;
    font-size: 1.3rem;
    font-weight: bolder;
  }
}